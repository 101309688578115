import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Query from './components/Query';
import { Overlay, Popover } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import DiscordLink from './components/DiscordLink';

const MARKETPLACES = [
  {
    name: 'opensea',
    url: 'https://opensea.io/'
  },
  {
    name: 'joepegs',
    url: 'https://joepegs.com/'
  }
]

function App() {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      setShowTooltip(false);
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    }

  }, [])

  return (
    <div className="container">
      <h1 className='mt-5'>NFT Details Explorer</h1>
      <div className='d-flex align-items-center mb-4'>

        <p className='mb-0'>Paste marketplace url bellow to fetch NFT metadata</p>
        <button className='btn' onClick={(e) => {
          e.stopPropagation();
          setShowTooltip(!showTooltip)
        }} ref={tooltipTarget}>
          <i className="bi bi-info-circle-fill text-info h4 mb-0"></i>
        </button>
        <Overlay target={tooltipTarget.current} show={showTooltip} placement="bottom-end">
          {(props: any) => (
            <Popover id="overlay-example" {...props} >
              <div className='p-2'>
                <p className='mb-0'>Supported marketplaces:</p>
                <ul>
                  {MARKETPLACES.map(marketplace => <li key={marketplace.name}>
                    <a href={marketplace.url} target="_blank" rel='noreferrer'>{marketplace.name}</a>
                  </li>)}
                </ul>
              </div>
            </Popover>
          )}
        </Overlay>
      </div>

      <Query />

      <DiscordLink />

    </div>
  );
}

export default App;
