import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import QueryResult from '../models/QueryResult'
import { handleIPFS } from '../utils/shared/query';
interface Props {
    result: QueryResult
}
export default function DisplayCard({ result }: Props) {
    const imageURL = handleIPFS(result?.metadata.image);

    const isImmutable = result.metadataUrl?.includes('ipfs') || result.metadataUrl?.includes('arweave');

    const renderMutableBadge = () => {
        if (isImmutable) {
            return <span className="badge rounded-pill bg-success">Immutable</span>
        }
        return <span className="badge rounded-pill bg-info">Mutable</span>
    }

    const renderMutabilityInfo = (props: any) => {
        const text = isImmutable
            ? 'NFT info is on IPFS or Arweave, therefore it is not possible to change.'
            : 'NFT is on centralized architecture, therefore the creator can change or destroy the metadata including the image.';

        return <Popover id="button-tooltip" {...props}>
            <div className='p-2'>
                {text}
            </div>
        </Popover>
    };

    return (
        <div className='nft-card'>
            <div className='nft-card-content'>

                <h2>{result.contractName} #{result.tokenId}</h2>
                <img src={imageURL}
                    alt={result.metadata.name}
                    style={{
                        maxWidth: '100%',
                    }} />


                <div className='mt-2'>
                    <OverlayTrigger overlay={renderMutabilityInfo} placement='bottom'>
                        {renderMutableBadge()}
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}
