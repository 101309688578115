import React from 'react'
import logo from '../assets/discord-mark-white.svg'

const wrapperSize = '90px'
const iconSize = '60px'

export default function DiscordLink() {

    return (
        <div style={{
            position: 'fixed',
            bottom: '0',
            right: '0',
        }}>
            <a href="https://discord.gg/edAjf3FwbB" target="_blank" rel="noreferrer">
                <div className='center m-2 discord-circle' style={{
                    width: wrapperSize,
                    height: wrapperSize,
                }}>
                    <img src={logo} alt="discord" style={{ maxHeight: iconSize, maxWidth: iconSize }} />
                </div>
            </a>
        </div>
    )
}
