import React, { useEffect } from 'react'
import QueryResult from '../models/QueryResult';
import { parseQuery } from '../utils/shared/query'
import DisplayResult from './DisplayResult';
import { CustomEvent } from '@piwikpro/react-piwik-pro'
import { fetchNFTDetails } from '../utils/api';

const DEFAULT_QUERY = 'https://joepegs.com/item/avalanche/0x2670020e207e24ab9b12edb1c4524786f18289b8/1/';

export default function Query() {
    const [query, setQuery] = React.useState<string>(DEFAULT_QUERY)
    const [result, setResult] = React.useState<QueryResult | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string>('')


    useEffect(() => {
        const queryParams = parseQuery(query);

        if (queryParams) {
            CustomEvent.trackEvent('loading_nft_query_parsed', 'Marketplace', queryParams.marketplace);


            setLoading(true);
            setError('');
            fetchNFTDetails(queryParams)
                .then(setResult)
                .catch(e => setError(e.message))
                .finally(() => setLoading(false))
        }
        const queryParts = query.split('/')
            .filter(item => !!item)
            .filter(item => !item.startsWith('http'))
        if (queryParts.length > 0) {
            CustomEvent.trackEvent('loading_nft_query_not_parsed', 'Marketplace', queryParts[0]);
        }


    }, [query])

    return (
        <div>
            <input type="text" className='form-control'
                placeholder='https://opensea.io/assets/ethereum/0x6339e5e072086621540d0362c4e3cea0d643e114/6890'
                value={query} onChange={e => setQuery(e.target.value)} />

            {error && <div className='alert alert-danger mt-2'>{error}</div>}

            {loading
                ? <div className='alert alert-info mt-2'>Loading...</div>
                : <DisplayResult result={result} />}

        </div>
    )
}
