import React from 'react'
import QueryResult from '../models/QueryResult'
import DisplayCard from './DisplayCard';
import DisplayMetadata from './DisplayMetadata';

interface Props {
    result: QueryResult | null
}



export default function DisplayResult(props: Props) {
    const { result } = props;

    if (!result) return null;

    return (
        <div className='row mt-5'>
            <div className='col-12 col-lg-4 p-3'>
                <DisplayCard result={result} />
            </div>
            <div className="col-12 col-lg-8 p-3">
                <DisplayMetadata metadata={result.metadata} />
            </div>
        </div>
    )
}


