import QueryParams from "../models/QueryParams"
import QueryResult from "../models/QueryResult"

const functinsURL = process.env.REACT_APP_USE_EMULATORS
    ? 'http://127.0.0.1:5001/nft-details/europe-west1'
    : 'https://nft-details.com'

export const fetchNFTDetails = async (queryParams: QueryParams): Promise<QueryResult | null> => {
    const result = await fetch(`${functinsURL}/fetchNFTDetails`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(queryParams),
    });

    return result.json();
}
