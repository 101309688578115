import React, { useEffect } from 'react'

interface Props {
    metadata: any;
}

const SPEED = 10;
export default function DisplayMetadata(props: Props) {
    const metadata = props.metadata;

    const [carrigeReturn, setCarrigeReturn] = React.useState(0);
    const [currentInterval, setCurrentInterval] = React.useState<NodeJS.Timer | null>(null);


    useEffect(() => setCarrigeReturn(0), [metadata]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCarrigeReturn(cr => cr + 1);
        }, SPEED);
        setCurrentInterval(interval);
        return () => clearInterval(interval);
    }, [metadata])

    useEffect(() => {
        if (currentInterval && carrigeReturn >= JSON.stringify(metadata, undefined, 2).length) {
            clearInterval(currentInterval);
            setCurrentInterval(null);
        }
    }, [carrigeReturn, currentInterval, metadata])


    const visibleMetadata = JSON.stringify(metadata, undefined, 2).substring(0, carrigeReturn);

    return (
        <div className='code'>

            <pre className='mb-0 p-2'
                style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: syntaxHighlight(visibleMetadata) }} />
        </div>
    )
}

function syntaxHighlight(json: any) {
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match: any) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}