import QueryParams from "../../models/QueryParams";


const CHAIN_ID: Record<string, number> = {
    "ethereum": 1,
    "avalanche": 43114,
    "arbitrum": 42161,
};

export const parseQuery = (query: string): QueryParams | null => {
    const openSeaMatch = query.match(/opensea.io\/assets\/(.*)\/(0x.*)\/(.*)/);
    if (openSeaMatch) {
        const chainId = CHAIN_ID[openSeaMatch[1] || ""];
        return {
            chainId,
            contractAddress: openSeaMatch[2],
            nftId: openSeaMatch[3],
            marketplace: "opensea",
        };
    }

    const joepegsMatch = query.match(/joepegs.com\/item\/(.*)\/(0x.*)\/([0-9]+)/);
    if (joepegsMatch) {
        const chainId = CHAIN_ID[joepegsMatch[1] || ""];
        return {
            chainId,
            contractAddress: joepegsMatch[2],
            nftId: joepegsMatch[3],
            marketplace: "joepegs",
        };
    }


    return null;
};

export const handleIPFS = (url?: string) => {
    if (!url) {
        return url;
    }

    if (url.startsWith("ipfs://")) {
        return `https://ipfs.io/ipfs/${url.substring(7)}`;
    }
    return url;
};
